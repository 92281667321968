import React from 'react';
import ReactDOM from 'react-dom/client'
import App from './App';
import { ConfirmContextProvider } from "./store/confirmation/ConfirmContextProvider";
import ConfirmDialog from "./components/confirmDialog/ConfirmDialog";
import AppProvider from './Providers';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<AppProvider>
			<ConfirmContextProvider>
				<App />
				<ConfirmDialog />
			</ConfirmContextProvider>
		</AppProvider>
	</React.StrictMode>
);
