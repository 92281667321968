import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import agentChatSlice from 'src/core/redux/agentChatSlice/agentChatSlice';
import { profileService } from 'src/services/profile.service';
import { utilityService } from 'src/services/utility.service';
import { socket } from 'src/socket';
import useAuth from './useAuth';

const useAgentChatSession = () => {
	const { connected } = useSelector((state) => state.agentChat);
	const dispatch = useDispatch();
	const { token } = useAuth();
	useEffect(() => {
		if (utilityService.isEndUserBrowsing() || !token) {
			return;
		}
		dispatch(agentChatSlice.actions.resetSlice());
		socket.connect();
		function onConnect() {
			dispatch(agentChatSlice.actions.setConnectedState(true));
		}

		function onDisconnect() {
			dispatch(agentChatSlice.actions.setConnectedState(false));
		}

		function onChatRequestReceived(data) {
			dispatch(agentChatSlice.actions.setIncomingChatRequest(data));
			dispatch(agentChatSlice.actions.setErrorMessage(''));
		}

		function onMessageReceived(data) {
			dispatch(agentChatSlice.actions.onMessageReceived(data));
		}

		function onUserTyping(data) {
			if (
				data.from?.id &&
				data.from?.id !== profileService.profile?.userId
			) {
				dispatch(
					agentChatSlice.actions.setUserTypingStatus(
						Boolean(data.isTyping),
					),
				);
			}
		}

		function onUserLeftRoom(data) {
			dispatch(agentChatSlice.actions.setUserLeftRoomData(data));
		}

		function onSessionTimeout(data) {
			dispatch(agentChatSlice.actions.resetSession());
			dispatch(agentChatSlice.actions.setErrorMessage(data.errorMessage));
		}

		socket.on('connect', onConnect);
		socket.on('disconnect', onDisconnect);
		socket.on('listenChatRequestForAgent', onChatRequestReceived);
		socket.on('receivedMessage', onMessageReceived);
		socket.on('messageTypingReceived', onUserTyping);
		socket.on('leftRoom', onUserLeftRoom);
		socket.on('error', onSessionTimeout);
		return () => {
			socket.off('connect', onConnect);
			socket.off('disconnect', onDisconnect);
			socket.off('listenChatRequestForAgent', onChatRequestReceived);
			socket.off('receivedMessage', onMessageReceived);
			socket.off('messageTypingReceived', onUserTyping);
			socket.off('leftRoom', onUserLeftRoom);
			socket.off('error', onSessionTimeout);
			socket.disconnect();
		};
	}, [dispatch, token]);

	return {
		connected,
	};
};

export default useAgentChatSession;
