import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import userChatSlice from 'src/core/redux/userChatSlice/userChatSlice';
import { profileService } from 'src/services/profile.service';
import { utilityService } from 'src/services/utility.service';
import { socket } from 'src/socket';
import useAuth from './useAuth';

const useUserChatSession = () => {
	const { connected } = useSelector((state) => state.userChat);
	const dispatch = useDispatch();

	const { token } = useAuth();

	useEffect(() => {
		if (!utilityService.isEndUserBrowsing() || !token) {
			return;
		}
		dispatch(userChatSlice.actions.resetSlice());
		socket.connect();
		function onConnect() {
			dispatch(userChatSlice.actions.setConnectedState(true));
		}

		function onDisconnect() {
			dispatch(userChatSlice.actions.setConnectedState(false));
		}

		function onChatRequestAccepted(data) {
			dispatch(userChatSlice.actions.onChatRequestAccepted(data));
		}
		function onChatRequestRejected(data) {
			dispatch(userChatSlice.actions.onChatRequestRejected(data));
		}

		function onMessageReceived(data) {
			dispatch(userChatSlice.actions.onMessageReceived(data));
		}

		function onAgentTyping(data) {
			if (
				data.from?.id &&
				data.from?.id !== profileService.profile?.userId
			) {
				dispatch(
					userChatSlice.actions.setAgentTypingStatus(
						Boolean(data.isTyping),
					),
				);
			}
		}

		function onUserLeftRoom(data) {
			dispatch(userChatSlice.actions.setAgentLeftRoomData(data));
		}


		function onSessionTimeout(data) {
			dispatch(userChatSlice.actions.resetSession());
			dispatch(userChatSlice.actions.setErrorMessage(data.errorMessage));
		}

		socket.on('connect', onConnect);
		socket.on('disconnect', onDisconnect);
		socket.on('agentAcceptedChatRequest', onChatRequestAccepted);
		socket.on('agentNotAvailable', onChatRequestRejected);
		socket.on('receivedMessage', onMessageReceived);
		socket.on('messageTypingReceived', onAgentTyping);
		socket.on('leftRoom', onUserLeftRoom);
		socket.on('error', onSessionTimeout);
		return () => {
			socket.off('connect', onConnect);
			socket.off('disconnect', onDisconnect);
			socket.off('agentAcceptedChatRequest', onChatRequestAccepted);
			socket.off('agentNotAvailable', onChatRequestRejected);
			socket.off('receivedMessage', onMessageReceived);
			socket.off('messageTypingReceived', onAgentTyping);
			socket.off('leftRoom', onUserLeftRoom);
			socket.off('error', onSessionTimeout);
			socket.disconnect();
		};
	}, [dispatch, token]);

	return {
		connected,
	};
};

export default useUserChatSession;
