import { createSlice } from '@reduxjs/toolkit';
import {
	CHAT_ROOM_STATUS,
	USER_CHAT_VIEW,
} from 'src/components/ChatWidget/constants';

const initialState = {
	connected: false,
	isWidgetExpanded: false,
	currentView: USER_CHAT_VIEW.BEGIN_CHAT,
	chatRoomStatus: CHAT_ROOM_STATUS.IDLE,
	activeRoomData: null,
	messages: [],
	isTyping: false,
	errorMessage: '',
	agentLeftRoomData: null,
};

const userChatSlice = createSlice({
	name: 'userChat',
	initialState,
	reducers: {
		setWidgetExpandedState: (state, action) => {
			state.isWidgetExpanded = action.payload;
		},
		setConnectedState: (state, action) => {
			state.connected = action.payload;
		},
		setErrorMessage: (state, action) => {
			state.errorMessage = action.payload;
		},
		setChatRoomStatus: (state, action) => {
			state.chatRoomStatus = action.payload;
		},
		setAgentTypingStatus: (state, action) => {
			state.isTyping = action.payload;
		},
		onChatRequestAccepted: (state, action) => {
			state.currentView = USER_CHAT_VIEW.CHAT_SCREEN;
			state.chatRoomStatus = CHAT_ROOM_STATUS.ROOM_ACTIVE;
			state.activeRoomData = action.payload;
		},
		setActiveRoomData: (state, action) => {
			state.activeRoomData = action.payload;
		},
		setCurrentView: (state, action) => {
			state.currentView = action.payload;
		},
		onChatRequestRejected: (state, action) => {
			state.currentView = USER_CHAT_VIEW.BEGIN_CHAT;
			state.chatRoomStatus = CHAT_ROOM_STATUS.IDLE;
			state.errorMessage = action.payload.message;
		},
		onMessageReceived: (state, action) => {
			state.messages.push(action.payload);
		},
		setMessages: (state, action) => {
			state.messages = [];
		},
		setAgentLeftRoomData: (state, action) => {
			state.agentLeftRoomData = action.payload;
		},
		resetSession: (state) => ({
			...initialState,
			connected: state.connected,
			isWidgetExpanded: state.isWidgetExpanded
		}),
		resetSlice: () => initialState,
	},
});

export default userChatSlice;
