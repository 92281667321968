import Cookies from 'js-cookie';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import authSlice from 'src/core/redux/authSlice';
import config from 'src/config';
import { setAppInitializationStatus } from 'src/core/redux/globalSlice/globalSlice';
import { STATUS_TYPES } from 'src/Constants';
import { profileService } from 'src/services/profile.service';
import { fetchWrapper } from 'src/utils/fetch-wrapper';
import { utilityService } from 'src/services/utility.service';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { alertService } from 'src/services/alert.service';

const getTokenExpiry = () => {
	// 5hrs Expiry
	return new Date(new Date().getTime() + 5 * 60 * 60 * 1000);
};

const compareTokens = (portalToken, eicToken) => {
	if (!portalToken || !eicToken) {
		return false;
	}

	try {
		const portalData = jwtDecode(portalToken);
		const eicUserData = jwtDecode(eicToken);
		if (!portalData?.userId) {
			return false;
		}

		return portalData.userId === eicUserData?.gUId;
	} catch (error) {
		return false;
	}
};

const useAuth = () => {
	const { token, profile ,oboProfile } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const redirectToPortal = () => {
		window.location.replace(
			`${config.portalUrl}/login?returnURL=${window.location.href}`,
		);
	};

	const saveToken = useCallback(
		async (token, updateCookie) => {
			if (updateCookie) {
				Cookies.set(config.tokenKey, token, {
					expires: getTokenExpiry(),
				});
			}
			dispatch(authSlice.actions.setToken(token));
			profileService.saveToken(token);
			try {
				const res = await fetchWrapper.post(
					`${config.apiURL}${config.getRoles}`,
				);
				profileService.setRoles(res.data);
			} catch (error) {
				profileService.setRoles([]);
			}
			dispatch(setAppInitializationStatus(STATUS_TYPES.SUCCESS));
		},
		[dispatch],
	);

	const fetchEnterpriseSettings = useCallback(async () => {
		try {
			const res = await fetchWrapper.post(
				`${config.apiURL}${config.getSettings}`,
				{
					name: window.location.host.split('.')[0],
				},
			);
			profileService.setSettings(res);
			return res;
		} catch (error) {}
	}, []);

	const saveImpersonationToken = useCallback(
		(impersonateToken, token) => {
			dispatch(authSlice.actions.setOboProfile(token));
			saveToken(impersonateToken);
		},
		[dispatch, saveToken],
	);

	const impersonateLogin = useCallback(async (email) => {
		try {
			utilityService.showLoader();
			const response = await fetchWrapper.post(
				`${config.apiURL}${config.impersonate}`,
				{ email },
			);
			if (response?.access_token) {
				Cookies.set(config.impersonateToken, response?.access_token, {
					expires: getTokenExpiry(),
				});
				window.location.href = '/mainpage/update-profile';
			} else {
				throw Error(response);
			}
		} catch (error) {
			alertService.error(error);
		} finally {
			utilityService.removeLoader();
		}
	}, []);

	const impersonateLogout = useCallback(() => {
		Cookies.remove(config.impersonateToken);
		window.location.href = '/mainpage/update-profile';
	}, []);

	const validateSession = useCallback(async () => {
		let isSSOAvailable = false;
		if (window.location.pathname.includes('idp-login-')) {
			dispatch(setAppInitializationStatus(STATUS_TYPES.SUCCESS));
			return;
		}
		const token = Cookies.get(config.tokenKey);
		const impersonateToken = Cookies.get(config.impersonateToken);

		if (impersonateToken && token) {
			saveImpersonationToken(impersonateToken, token);
			return;
		}
		const params = new URLSearchParams(window.location.search);
		const portalToken =
			params.get('token') ?? Cookies.get(config.portalTokenKey);
		if (params.get('token')) {
			// For localhost
			Cookies.set(config.portalTokenKey, portalToken, {
				expires: getTokenExpiry(),
			});
		}
		if (token && compareTokens(portalToken, token)) {
			saveToken(token);
			return;
		}

		if (utilityService.isEndUserBrowsing()) {
			const res = await fetchEnterpriseSettings();
			if (
				res?.signupType === 'IsSSO' ||
				res?.signupType === 'IsSSODomainSpecific' ||
				res?.signupType === 'IsBoth'
			) {
				isSSOAvailable = true;
			}
		}

		if (!portalToken) {
			if (isSSOAvailable) {
				navigate('/login');
				dispatch(setAppInitializationStatus(STATUS_TYPES.SUCCESS));
				return;
			}

			redirectToPortal();
			return;
		}
		try {
			const res = await fetch(config.apiURL + config.checkLogin, {
				method: 'POST',
				credentials: 'include',
				body: JSON.stringify({
					isAgent: !utilityService.isEndUserBrowsing(),
				}),
			});
			if(res.status===403){
				dispatch(setAppInitializationStatus(STATUS_TYPES.SUCCESS));
				navigate("/access-denied")
				return 
			}
			const response = await res.json();
			if (!response?.access_token) {
				throw new Error(response);
			}
			saveToken(response.access_token, true);
		} catch (error) {
			redirectToPortal();
		}
	}, [
		dispatch,
		fetchEnterpriseSettings,
		saveImpersonationToken,
		navigate,
		saveToken,
	]);

	const setToken = useCallback(
		(token) => {
			dispatch(authSlice.actions.setToken(token));
		},
		[dispatch],
	);

	return {
		token,
		profile,
		oboProfile,
		setToken,
		validateSession,
		redirectToPortal,
		saveToken,
		impersonateLogin,
		impersonateLogout
	};
};

export default useAuth;
