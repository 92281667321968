import { createSlice } from '@reduxjs/toolkit';
import { getUserIdKey } from 'src/utils/utils';
import config from 'src/config';
import { profileService } from 'src/services/profile.service';
import { STATUS_TYPES } from 'src/Constants';

const enterprise = profileService.profile
	? localStorage.getItem(getUserIdKey(config.selectedEnterpriseKey))
	: null;
export const initialState = {
	selectedEnterprise: enterprise ? JSON.parse(enterprise) : null,
	showEnterpriseModal: false,
	loginDisplay: false,
	profileImageTemp: null,
	appInitializationStatus: STATUS_TYPES.IDLE,
};

export const globalSlice = createSlice({
	name: 'global',
	initialState,
	reducers: {
		updateSelectedEnterprise: (state, action) => {
			state.selectedEnterprise = action.payload;
			localStorage.setItem(
				getUserIdKey(config.selectedEnterpriseKey),
				action.payload ? JSON.stringify(action.payload) : '',
			);
		},
		updateEnterpriseModalStatus: (state, action) => {
			state.showEnterpriseModal = action.payload;
		},
		updateLoginDisplay: (state, action) => {
			state.loginDisplay = action.payload;
		},
		updateProfileImageTemp: (state, action) => {
			state.profileImageTemp = action.payload;
		},
		setAppInitializationStatus: (state, action) => {
			state.appInitializationStatus = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateSelectedEnterprise,
	updateEnterpriseModalStatus,
	updateLoginDisplay,
	updateProfileImageTemp,
	setAppInitializationStatus
} = globalSlice.actions;

export default globalSlice.reducer;
