import { createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';

const initialState = {
	token: null,
	profile: null,
	oboProfile: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setToken: (state, action) => {
			try {
				state.token = action.payload;
				state.profile = action.payload
					? jwtDecode(action.payload)
					: null;
			} catch (error) {
				console.error('Error decoding tokeb');
			}
		},
		setOboProfile: (state, action) => {
			try {
				let decoded = jwtDecode(action.payload);
				state.oboProfile = decoded;
			} catch (error) {
				console.error('Error decoding tokeb');
			}
		},
	},
});

export default authSlice;
