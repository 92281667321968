import React from 'react'
import { ChatWidget } from './ChatWidget'
import useUserChatSession from 'src/hooks/useUserChatSession'

export const ChatWidgetWrapper = () => {

    const { connected } = useUserChatSession()


    return (
        connected && <ChatWidget />
    )
}
