import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import agentChatSlice from 'src/core/redux/agentChatSlice/agentChatSlice';
import { socket } from 'src/socket';

const useAgentChatAction = () => {
	const dispatch = useDispatch();
	const { activeRoomData, chatRequestData, userLeftRoomData } = useSelector(
		(state) => state.agentChat,
	);

	const acceptChatRequest = useCallback(() => {
		dispatch(agentChatSlice.actions.acceptChatRequest(chatRequestData));
		socket.emit('acceptChatRequest', {
			room: chatRequestData?.room,
		});
	}, [dispatch, chatRequestData]);

	const rejectChatRequest = useCallback(() => {
		dispatch(agentChatSlice.actions.rejectChatRequest());
		socket.emit('rejectChatRequest', {
			room: chatRequestData?.room,
		});
	}, [chatRequestData?.room, dispatch]);

	const sendMessage = useCallback(
		(message) => {
			socket.emit('sendMessage', {
				message,
				room: activeRoomData?.room,
			});
		},
		[activeRoomData?.room],
	);

	const sendTypingStatus = useCallback(
		(isTyping) => {
			socket.emit('messageTypingSend', {
				room: activeRoomData?.room,
				isTyping,
			});
		},
		[activeRoomData?.room],
	);


	const leaveRoom = useCallback(() => {
		dispatch(agentChatSlice.actions.resetSession());
		if (!userLeftRoomData) {
			socket.emit('leaveRoom', {
				room: activeRoomData?.room,
			});
		}
	}, [activeRoomData?.room, dispatch, userLeftRoomData]);

	return {
		acceptChatRequest,
		rejectChatRequest,
		sendMessage,
		sendTypingStatus,
		leaveRoom,
	};
};

export default useAgentChatAction;
