import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	connected: false,
	isDialogOpen: false,
	chatRequestData: null,
	activeRoomData: null,
	messages: [],
	isTyping: false,
	userLeftRoomData: null,
	errorMessage: '',
};

const agentChatSlice = createSlice({
	name: 'agentChat',
	initialState,
	reducers: {
		setConnectedState: (state, action) => {
			state.connected = action.payload;
		},
		setIncomingChatRequest: (state, action) => {
			if (
				!state.chatRequestData &&
				(!state.activeRoomData || state.userLeftRoomData)
			) {
				state.isDialogOpen = true;
				state.chatRequestData = action.payload;
			}
		},
		setUserTypingStatus: (state, action) => {
			state.isTyping = action.payload;
		},
		acceptChatRequest: (state, action) => {
			state.activeRoomData = action.payload;
			state.isDialogOpen = false;
			state.chatRequestData = null;
		},
		setActiveRoomData: (state, action) => {
			state.activeRoomData = action.payload;
		},
		rejectChatRequest: (state) => {
			state.isDialogOpen = false;
			state.chatRequestData = null;
		},
		onMessageReceived: (state, action) => {
			state.messages.push(action.payload);
		},
		setMessages: (state, action) => {
			state.messages = [];
		},
		setUserLeftRoomData: (state, action) => {
			state.userLeftRoomData = action.payload;
		},
		setErrorMessage: (state, action) => {
			state.errorMessage = action.payload;
		},
		resetSession: (state) => ({
			...initialState,
			connected: state.connected,
			chatRequestData: state.chatRequestData,
		}),
		resetSlice: () => initialState,
	},
});

export default agentChatSlice;
