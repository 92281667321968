import React, { useCallback, useRef, useState } from 'react'
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import styles from './ChatWidget.module.scss';
import ChatMessage from './ChatMessage';
import { InputAdornment, InputBase } from '@mui/material';
import ChatTyping from './ChatTyping';
import { useSelector } from 'react-redux';
import useUserChatAction from 'src/hooks/useUserChatAction';
import clsx from 'clsx';
import SafeImage from 'src/utils/SafeImage';




const ChatScreen = () => {
    const [query, setQuery] = useState('');
    const { messages, isTyping, activeRoomData, agentLeftRoomData } = useSelector((state) => state.userChat);
    const { sendMessage, sendTypingStatus, leaveRoom } = useUserChatAction()
    const chatRef = useRef(null)

    const handleSendMessage = () => {
        if (!query.trim() || agentLeftRoomData) {
            return
        }
        sendMessage(query)
        setQuery('')
        setTimeout(() => {
            scrollToBottom()
        }, 500);
    }

    const scrollToBottom = useCallback(
        () => {
            if (chatRef.current) {
                chatRef.current.scrollTo({
                    top: chatRef.current.scrollHeight
                })
            }
        },
        [],
    )
    return (
        <>

            <div
                className={clsx(
                    styles.header,
                    styles["bottom-rounded"],
                    'p-3',
                )}
            >
                <button onClick={leaveRoom} className={clsx(styles["leave-chat-btn"], 'button button--small button--rounded button--outlined')}>
                    End chat
                </button>
                <div className='mt-2 d-flex align-items-center justify-content-center flex-column gap-1'>
                    <SafeImage src={activeRoomData?.userId?.profileImage} width={60} height={60} className="rounded-circle" />
                    <p className='text-center m-0 text-capitalize font-weight-bold'>{activeRoomData?.userId?.fname?.toLowerCase()} {activeRoomData?.userId?.lname?.toLowerCase()}</p>
                    <p className='tetx-center m-0 font-weight-light'>{activeRoomData?.userId?.title}</p>
                </div>
            </div>
            <div className={clsx(styles.content, "p-3")} ref={chatRef}>
                <div className='d-flex justify-content-center'>
                    <div className={clsx(styles.initialMessage)}>
                        {activeRoomData?.message}
                    </div>
                </div>
                {
                    messages.map(message => <ChatMessage key={message} data={message} />)
                }
                {
                    isTyping && <ChatTyping />
                }
                {
                    agentLeftRoomData ? <div className='d-flex justify-content-center text-center text-dark border-top'><p>{agentLeftRoomData?.message}</p></div> : null
                }

            </div>
            <div className={styles.footer}>
                <InputBase
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSendMessage()
                        }
                    }}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Type your message and hit Enter"
                    sx={{ padding: (theme) => theme.spacing(1, 2) }}
                    fullWidth
                    onFocus={() => sendTypingStatus(true)}
                    onBlur={() => sendTypingStatus(false)}
                    disabled={Boolean(agentLeftRoomData)}
                    endAdornment={
                        <InputAdornment position="end">
                            <SendRoundedIcon
                                onClick={handleSendMessage}
                                sx={{
                                    color: 'var(--color-blue)',
                                    cursor: 'pointer',
                                }}
                            />
                        </InputAdornment>
                    }
                />
            </div>
        </>
    )
}

export default ChatScreen