
import React, { useEffect, useState } from "react"
import Avatar from 'src/quantum_ticketing/GeneralNav/avatar.png';

const SafeImage = ({ defaultImage, src, ...props }) => {

    const [imageLoaded, setImageLoaded] = useState(false);
    const [error, setError] = useState(false)


    useEffect(() => {
        setError(false)
        const img = new Image()
        img.src = src;
        img.onload = () => {
            setImageLoaded(true)
        }

        return () => {

        }
    }, [src])

    return <img {...props} onError={() => setError(true)} src={imageLoaded && !error ? src : defaultImage ?? Avatar} alt={props.alt ?? ""} />
}

export default SafeImage