export const FORM_TYPE = {
	LABEL_TEXT: 'Label Text',
	SINGLE_LINE_TEXT: 'Single Line Text',
	NUMBER: 'Number',
	PARAGRAPH_TEXT: 'Paragraph Text',
	CHECKBOX: 'Checkbox',
	DROPDOWN: 'Dropdown',
	CALENDAR: 'Calendar',
	MULTISELECT_DROPDOWN: 'Multi Select Dropdown',
	EMPLOYEE_SELECT: 'Employee Select',
	EMPLOYEE_SELECT_FREE_SOLO: 'Employee Select Free Solo',
};

export const DEFAULT_FORM_TYPE = {
	...FORM_TYPE,
	MULTI_INPUT_EMAILS_FIELD: 'Multiple Input Field (Email)',
	MULTI_INPUT_FREE_HAND_FIELD: 'Multiple Input Field (Free Hand)',
};

export const DROPDOWN_TYPES = [
	'Dropdown',
	'Dependent Field',
	'Company List',
	'Groups',
	'Type',
	'Priority',
	'Product',
	'Source',
	'Tag',
	'Multi Select Dropdown',
];

export const STATUS_TYPES = {
	IDLE: 'IDLE',
	LOADING: 'LOADING',
	SUCCESS: 'SUCCESS',
	ERROR: 'ERROR',
	UPDATING: 'UPDATING',
};

export const SURVEY_FIELD_TYPES = {
	RATING: 0,
	TEXT: 1,
	DESCRIPTION: 2,
};


