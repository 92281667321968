import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { STATUS_TYPES } from 'src/Constants';
import useAuth from 'src/hooks/useAuth';

const AuthWrapper = ({ children }) => {
	const { validateSession } = useAuth();
	const appInitializationStatus = useSelector(
		(state) => state.global.appInitializationStatus,
	);

	useEffect(() => {
		validateSession();
		return () => {};
	}, [validateSession]);

	return appInitializationStatus === STATUS_TYPES.SUCCESS ? (
		children
	) : (
		<div className="vw-100 vh-100 d-flex align-items-center justify-content-center">
			<ClipLoader color="#009FDF" />
		</div>
	);
};

export default AuthWrapper;
