import React from 'react'
import styles from "./ChatWidget.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage } from '@fortawesome/free-solid-svg-icons'
import { ChatSection } from './ChatSection'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import userChatSlice from 'src/core/redux/userChatSlice/userChatSlice'

export const ChatWidget = () => {

    const isWidgetExpanded = useSelector(state => state.userChat.isWidgetExpanded)
    const dispatch = useDispatch()


    const toggleExpandedState = () => {
        dispatch(userChatSlice.actions.setWidgetExpandedState(!isWidgetExpanded))
    }

    return (
        <div className={styles.wrapper}>
            {isWidgetExpanded && <ChatSection />}
            <div onClick={toggleExpandedState} className={styles.fab}>
                <FontAwesomeIcon icon={faMessage} />
            </div>
        </div>
    )
}
