import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Modal, ProgressBar } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAgentChatAction from 'src/hooks/useAgentChatAction';


const DialogContent = () => {

    const chatRequestData = useSelector((state) => state.agentChat.chatRequestData);
    const { acceptChatRequest, rejectChatRequest } = useAgentChatAction()
    const navigate = useNavigate()
    const [timer, setTimer] = useState(30)
    const timerRef = useRef(30)

    useEffect(() => {
        timerRef.current = timer

        return () => {

        }
    }, [timer])


    const handleAcceptClick = () => {
        acceptChatRequest()
        navigate("/mainpage/chat-support")
    }

    const handleRejectClick = useCallback(() => {
        rejectChatRequest()
    }, [rejectChatRequest])

    useEffect(() => {
        setTimer(30)
        const intervalId = setInterval(() => {
            if (timerRef.current > 1) {
                setTimer(prev => prev - 1)
            } else {
                handleRejectClick()
            }
        }, 1000)
        return () => {
            clearInterval(intervalId)
        }
    }, [handleRejectClick])


    return <>
        <Modal.Header>
            <Modal.Title>Incoming Chat Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h6>
                {
                    chatRequestData?.message
                }
            </h6>
            <div className='mt-2'>
                <div className='d-flex align-items-center gap-1 justify-content-center'>
                    <p className='m-0'>Auto Reject in</p> <h6 className='m-0'>{timer} {timer > 9 ? "seconds" : "second"}</h6>
                </div>
                <ProgressBar className='mt-2' animated variant="danger" now={30-timer} min={0} max={30} />
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button onClick={handleAcceptClick} className="button button--primary button--small button--rounded mr-1">
                Accept
            </button>
            <button onClick={handleRejectClick} className="button button--accent button--small button--rounded">
                Reject
            </button>
        </Modal.Footer>
    </>
}


const AgentChatRequestDialog = () => {

    const isDialogOpen = useSelector((state) => state.agentChat.isDialogOpen);

    return (
        <Modal show={isDialogOpen} centered>
            <DialogContent />
        </Modal>
    )
}

export default AgentChatRequestDialog