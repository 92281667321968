import { configureStore } from '@reduxjs/toolkit';
import emailsReducer from './emailSlice/emailSlice';
import businessRulesReducer from './businessRulesSlice/businessRulesSlice';
import enterprisesReducer from './enterprisesSlice/enterprisesSlice';
import surveysReducer from './surveySlice/surveySlice';

import globalReducer from './globalSlice/globalSlice';
import quickResponseReducer from './quickResponsesSlice/quickResponseSlice';
import tagsReducer from './tagsSlice/tagsSlice';
import languageReducer from './languagesSlice/languageSlice';
import announcementReducer from './announcementSlice/announcementSlice';
import userChatSlice from './userChatSlice/userChatSlice';
import agentChatSlice from './agentChatSlice/agentChatSlice';
import authSlice from './authSlice';

export const store = configureStore({
	reducer: {
		emails: emailsReducer,
		businessRules: businessRulesReducer,
		enterprises: enterprisesReducer,
		quickResponses: quickResponseReducer,
		tags: tagsReducer,
		surveys: surveysReducer,
		languages: languageReducer,
		global: globalReducer,
		announcement: announcementReducer,
		userChat: userChatSlice.reducer,
		agentChat: agentChatSlice.reducer,
		auth: authSlice.reducer,
	},
});
