import { useEffect, useState } from 'react';
import { fetchWrapper } from 'src/utils/fetch-wrapper';
import config from 'src/config';
const useMaintenance = () => {
	const [maintenanceEnabled, setMaintenanceEnabled] = useState(false);

	useEffect(() => {
		if(config.isLocalEnv){
			return;
		}
		let stopPolling = false;
		const startPolling = async () => {
			try {
				let response = await fetchWrapper.get(
					`${config.apiURL}${config.getApplicationSettings}`,
				);
				const data = response?.find(
					(dt) => dt.settingsName === 'maintenace',
				);
				if (data) {
					setMaintenanceEnabled(Boolean(data.value));
				}
			} catch (error) {
                // 
            }
			if (!stopPolling) {
				setTimeout(() => {
					startPolling();
				}, 5000);
			}
		};
		startPolling();
		return () => {
			stopPolling = true;
		};
	}, []);

	return {
		maintenanceEnabled,
	};
};

export default useMaintenance;
