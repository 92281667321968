import React from 'react'
import styles from "./ChatWidget.module.scss"
import { PulseLoader } from 'react-spinners'
import clsx from 'clsx'

const ChatTyping = () => {
    return (
        <div className={clsx(styles.message, styles.typing
        )}>

            <div className={styles["message-content"]}>
                <PulseLoader color='#0569FF' size={8} margin={0} />
            </div>
        </div>
    )
}

export default ChatTyping