export const USER_CHAT_VIEW = {
	BEGIN_CHAT: 'BEGIN_CHAT',
	CHAT_SCREEN: 'CHAT_SCREEN',
};

export const CHAT_ROOM_STATUS = {
	IDLE: 'IDLE',
	REQUEST_INITIATED: 'REQUEST_INITIATED',
	ROOM_ACTIVE: 'ROOM_ACTIVE',
};
