import { Fragment } from 'react';
import useMaintenance from 'src/hooks/useMaintenance';
import useNetworkState from 'src/hooks/useNetworkState';
import { ChatWidgetWrapper } from 'src/components/ChatWidget';
import AgentChatSupportWrapper from '../AgentChatSupport/AgentChatSupportWrapper';
import { Link } from '@mui/material';
import AuthWrapper from '../AuthWrapper';

const GlobalWrapper = ({ children }) => {
	const isOnline = useNetworkState();
	const { maintenanceEnabled } = useMaintenance();

	if (!isOnline) {
		return (
			<div className="vw-100 vh-100 d-flex align-items-center justify-content-center flex-column">
				<h4>No Internet Connection</h4>
				<h6>
					No Internet connection found, Please check your connection
					and try again
				</h6>
			</div>
		);
	}

	if (maintenanceEnabled) {
		return (
			<div className="vw-100 vh-100 d-flex align-items-center justify-content-center flex-column">
				<h1>We&rsquo;ll be back soon!</h1>
				<p>
					Sorry for the inconvenience but we&rsquo;re performing some
					maintenance at the moment. If you need to you can always{' '}
					<Link underline="hover" href="mailto:info@qs2500.com">
						contact us
					</Link>
					, otherwise we&rsquo;ll be back online shortly!
				</p>
				<p>&mdash; MaximumAccountability Team</p>
			</div>
		);
	}

	return (
		<AuthWrapper>
			{children}
			<ChatWidgetWrapper />
			<AgentChatSupportWrapper />
		</AuthWrapper>
	);
};

export default GlobalWrapper;
