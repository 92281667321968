const { useState, useEffect } = require('react');

const useNetworkState = () => {
	const [isOnline, setIsOnline] = useState(window.navigator.onLine);

	useEffect(() => {
		const setOnline = () => {
      console.log("cakked")
			setIsOnline(true);
		};

		const setOffline = () => {
      console.log("called")
			setIsOnline(false);
		};

		window.addEventListener('online', setOnline);
		window.addEventListener('offline', setOffline);

		return () => {
			window.removeEventListener('online', setOnline);
			window.removeEventListener('offline', setOffline);
		};
	}, []);

	return isOnline;
};

export default useNetworkState;
