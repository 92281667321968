import React from 'react';
import styles from './ChatWidget.module.scss';
import { useSelector } from 'react-redux';
import { USER_CHAT_VIEW } from './constants';
import BeginChatScreen from './BeginChatScreen';
import ChatScreen from './ChatScreen';






export const ChatSection = () => {
    const currentView = useSelector(state => state.userChat.currentView)

    return (
        <div className={styles['chat-section']}>
           
            {
                currentView === USER_CHAT_VIEW.BEGIN_CHAT && <BeginChatScreen />
            }
            {
                currentView === USER_CHAT_VIEW.CHAT_SCREEN && <ChatScreen />
            }

        </div>
    );
};
