import React from 'react'
import useAgentChatSession from 'src/hooks/useAgentChatSession'
import AgentChatRequestDialog from './AgentChatRequestDialog'

const AgentChatSupportWrapper = () => {
    const { connected } = useAgentChatSession()

    return (
        connected ? <AgentChatRequestDialog /> : null
    )
}

export default AgentChatSupportWrapper