import React, { useCallback } from 'react'
import { Translator } from 'react-auto-translate'
import { useSelector } from 'react-redux';



let languageCache = {};

const TranslationProvider = ({ children }) => {

    const languageState = useSelector((state) => state.languages.value);

    const getLanguageCache = useCallback((language, key) => {
        return languageCache[key]?.[language]
    }, [])


    const setLaguageCache = useCallback((language, key, value) => {
        if (!languageCache[key]) {
            languageCache[key] = {}
        }
        languageCache[key] = {
            ...languageCache[key],
            [language]: value
        }
    }, [])





    return (
        <Translator cacheProvider={{ get: getLanguageCache, set: setLaguageCache }} from="en" googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY} to={languageState}>
            {children}
        </Translator>
    )
}

export default TranslationProvider