import React from 'react'
import { profileService } from 'src/services/profile.service'
import styles from './ChatWidget.module.scss';
import { PulseLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import { CHAT_ROOM_STATUS } from './constants';
import { useDispatch } from 'react-redux';
import userChatSlice from 'src/core/redux/userChatSlice/userChatSlice';
import useUserChatAction from 'src/hooks/useUserChatAction';
import clsx from 'clsx';

const BeginChatScreen = () => {

    const { chatRoomStatus, errorMessage } = useSelector(state => state.userChat)
    const dispatch = useDispatch()
    const { initiateChatRequest } = useUserChatAction()

    const handleChatClick = () => {
        initiateChatRequest()
        dispatch(userChatSlice.actions.setChatRoomStatus(CHAT_ROOM_STATUS.REQUEST_INITIATED))
    }

    return (
        <>
            <div
                className={clsx(
                    styles.header,
                    'd-flex align-items-center p-3',
                )}
            >
                Chat Support
            </div>
            <div className={clsx(styles.content, "p-3 d-flex flex-column justify-content-end ")}>
                <div>
                    <h5>
                        {profileService.settings?.name}
                    </h5>
                    <p>We are Here to help you</p>
                    <p className='text-danger'>{errorMessage}</p>

                </div>
                <button onClick={handleChatClick} disabled={chatRoomStatus === CHAT_ROOM_STATUS.REQUEST_INITIATED} className='button button--blue button--rounded button--small mt-3'>
                    {chatRoomStatus === CHAT_ROOM_STATUS.REQUEST_INITIATED ? <PulseLoader size={8} margin={0} /> : "Chat with us now"}
                </button>
            </div>
        </>

    )
}

export default BeginChatScreen