import { CssBaseline, ThemeProvider, createTheme } from "@mui/material"
import { Provider } from "react-redux"
import { store } from "src/core/redux/store"
import TranslationProvider from "./TranslationProvider"

const theme = createTheme({
    typography: {
        fontFamily: 'Poppins, Arial, Helvetica, sans-serif'
    },
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                inputRoot: {
                    borderRadius: "10px",
                    padding: '4px 8px'
                }
            }
        }
    }
})

const AppProvider = ({ children }) => {

    return (
        <Provider store={store}>
            <TranslationProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    {children}
                </ThemeProvider>
            </TranslationProvider>
        </Provider>
    )
}

export default AppProvider