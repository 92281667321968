import React from 'react';
import styles from './ChatWidget.module.scss';
import { profileService } from 'src/services/profile.service';
import clsx from 'clsx';

const ChatMessage = ({ data }) => {
	const isSentByYou = data?.from?.id === profileService.profile?.userId;

	return (
		<div
			className={clsx(styles.message, {
				[styles.self]: isSentByYou,
			})}
		>
			<p className="p-0 m-0 text-dark">
				{isSentByYou
					? 'You'
					: `${data?.from?.fname} ${data?.from?.lname}`}
			</p>
			<div className={styles['message-content']}>{data.message}</div>
		</div>
	);
};

export default ChatMessage;
