import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
	CHAT_ROOM_STATUS,
} from 'src/components/ChatWidget/constants';
import userChatSlice from 'src/core/redux/userChatSlice/userChatSlice';
import { profileService } from 'src/services/profile.service';
import { socket } from 'src/socket';

const useUserChatAction = () => {
	const dispatch = useDispatch();
	const { activeRoomData, agentLeftRoomData } = useSelector(
		(state) => state.userChat,
	);
	const initiateChatRequest = useCallback(() => {
		socket.emit('sendChatRequestToAgent', {
			enterPriseId: profileService.settings.id,
		});
		dispatch(
			userChatSlice.actions.setChatRoomStatus(
				CHAT_ROOM_STATUS.REQUEST_INITIATED,
			),
		);
		dispatch(userChatSlice.actions.setErrorMessage(''));
	}, [dispatch]);

	const sendMessage = useCallback(
		(message) => {
			socket.emit('sendMessage', {
				message,
				room: activeRoomData?.room,
			});
		},
		[activeRoomData?.room],
	);

	const sendTypingStatus = useCallback(
		(isTyping) => {
			socket.emit('messageTypingSend', {
				room: activeRoomData?.room,
				isTyping,
			});
		},
		[activeRoomData?.room],
	);

	const leaveRoom = useCallback(() => {
		dispatch(userChatSlice.actions.resetSession());
		if (!agentLeftRoomData) {
			socket.emit('leaveRoom', {
				room: activeRoomData?.room,
			});
		}
	}, [activeRoomData?.room, agentLeftRoomData, dispatch]);

	return {
		initiateChatRequest,
		sendMessage,
		sendTypingStatus,
		leaveRoom,
	};
};

export default useUserChatAction;
