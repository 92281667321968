import { io } from 'socket.io-client';
import config from './config';
import { authHeader } from './utils/auth-header';

const URL = config.apiURL.replace('api/v1', '');
export const socket = io(URL, {
	autoConnect: false,
	transports: ['websocket'],
	auth: (cb) => {
		return cb(authHeader());
	},
});
